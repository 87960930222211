import type { Schemas } from "#shopware";

// Inspired by `@shopware-pwa\composables-next\src\useLocalWishlist.ts`

const _storageKey = "sw-last-seen-items";

const _lastSeenItems: Ref<string[]> = ref([]);

export function useLastSeenList() {
  function updateStorage() {
    const circular = findCircular(_lastSeenItems.value);
    if (circular) {
      console.log("circular reference found in last-seen-items-list");
    }
    localStorage.setItem(_storageKey, JSON.stringify(_lastSeenItems.value));
  }

  function getFromStorage() {
    if (typeof window == "undefined" || !localStorage) return undefined;
    return JSON.parse(localStorage.getItem(_storageKey) ?? "[]") as string[];
  }

  async function removeFromLastSeenList(id: string) {
    _lastSeenItems.value = _lastSeenItems.value?.filter((x) => x != id);
    updateStorage();
  }

  async function addToLastSeenList(id: string) {
    if (_lastSeenItems.value.includes(id)) return;

    if (_lastSeenItems.value.length >= maxItems) {
      _lastSeenItems.value.shift();
    }
    _lastSeenItems.value.push(id);
    updateStorage();
  }

  async function clearLastSeenList() {
    _lastSeenItems.value = [];
    updateStorage();
  }

  function getLastSeenItems() {
    const currentList = getFromStorage();
    if (Array.isArray(currentList) && currentList.length) {
      _lastSeenItems.value = currentList;
    }
  }

  function findCircular(
    obj: unknown,
    path: string = "",
    seen: WeakSet<object> = new WeakSet(),
  ): boolean {
    if (typeof obj === "object" && obj !== null) {
      if (seen.has(obj)) {
        console.log(`Circular reference detected at: ${path}`);
        return true;
      }
      seen.add(obj);

      const objRecord = obj as Record<string, unknown>;

      for (const key in objRecord) {
        if (Object.prototype.hasOwnProperty.call(objRecord, key)) {
          const newPath = path ? `${path}.${key}` : key;
          if (findCircular(objRecord[key], newPath, seen)) {
            return true; // exit early if circular reference is found
          }
        }
      }
      seen.delete(obj);
    }
    return false;
  }

  const maxItems = 6;
  const items = computed(() => _lastSeenItems.value);
  const count = computed(() => items.value.length);
  const containsProducts = computed(() => count.value > 0);

  return {
    getLastSeenItems,
    addToLastSeenList,
    removeFromLastSeenList,
    clearLastSeenList,
    items,
    count,
    containsProducts,
  };
}
